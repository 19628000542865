
.container-gradient {
  /* background: radial-gradient(farthest-corner at -0% 100%, #7247ff 30%, #00ffbe 95%); */
  /* background: radial-gradient(farthest-corner at -0% 100%, #6772e5 30%, #00cfff 95%); */
  background: radial-gradient(farthest-corner at -0% 100%, #457cff 30%, #3e71e7 95%);
  background-size: 400% 400%;

  -webkit-animation: gradientTransition 20s ease infinite;
  -moz-animation: gradientTransition 20s ease infinite;
  animation: gradientTransition 20s ease infinite;
}

.button-container a {
  position: relative;
  text-align: center;
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  margin: 0 auto;
  user-select: none;
  cursor: pointer;
  /* color: #6772e5; */
  color: #457cff;
  background: #fff;
  border-radius: 4px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px;
  height: 48px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-transition: all 0.12s ease-in-out;
  transition: all 0.12s ease-in-out;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.button-container a:before {
  content: "";
  transition: all 150ms ease;
  background: rgba(190, 197, 208, 0.25);
  border-radius: 4px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(0);
}
.button-container a:hover:before {
  transform: scale(1);
}
.button-container a:active:before {
  background: rgba(190, 197, 208, 0.5)
}

.button-container-alt a {
  position: relative;
  text-align: center;
  user-select: none;
  /* box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08); */
  margin: 0 auto;
  cursor: pointer;
  /* color: #6772e5; */
  color: #457cff;
  background: #fff;
  border-radius: 4px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px;
  height: 48px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-transition: all 0.04s ease-in-out;
  transition: all 0.04s ease-in-out;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.button-container-alt a:before {
  content: "";
  transition: all 150ms ease;
  background: rgba(190, 197, 208, 0.25);
  border-radius: 4px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(0);
}
.button-container-alt a:hover:before {
  transform: scale(1);
}
.button-container-alt a:active:before {
  background: rgba(190, 197, 208, 0.5)
}

.hover {
  display: block;
  animation: hover 5s ease-in-out infinite;
}

@-webkit-keyframes hover {
  0% {transform: translateY(3px)}
  50% {transform: translateY(-3px)}
  100% {transform: translateY(3px)}
}
@-moz-keyframes hover {
  0% {transform: translateY(3px)}
  50% {transform: translateY(-3px)}
  100% {transform: translateY(3px)}
}
@keyframes hover {
  0% {transform: translateY(3px)}
  50% {transform: translateY(-3px)}
  100% {transform: translateY(3px)}
}

@-webkit-keyframes gradientTransition {
  0% {background-position: 0% 50%}
  50% {background-position: 60% 50%}
  100% {background-position: 0% 50%}
}
@-moz-keyframes gradientTransition {
  0% {background-position: 0% 50%}
  50% {background-position: 60% 50%}
  100% {background-position: 0% 50%}
}
@keyframes gradientTransition {
  0% {background-position: 0% 50%}
  50% {background-position: 60% 50%}
  100% {background-position: 0% 50%}
}

@media only screen and (max-width: 1400px) {
  .section {
    flex-direction: column !important;
  }
  .item-text {
    width: 600px;
  }
  .footer-links a {
    display: block;
  }
}
