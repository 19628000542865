@import url("https://rsms.me/inter/inter-ui.css");

/* ::selection {
  color: rgb(255, 254, 252);
  background: #4880ff;
} */

* {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html,
body {
	/* height: 100%; */
	width: 100%;
	scroll-behavior: smooth;
}

html,
body,
* {
	font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	font-size: 14px;
	/* color: #202124; */
	/* color: #19233C; */
	color: rgb(20, 19, 19);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	background: rgb(255, 254, 253);
	margin: 0;
	padding: 0;
}

h1 {
	font-weight: 600;
	font-size: 48px;
	letter-spacing: -0.05px;
	font-size: 52px;
	letter-spacing: -0.75px;
}

h2 {
	font-weight: 500;
	font-size: 38px;
	letter-spacing: -0.05px;
}

h3 {
	font-weight: 500;
	font-size: 28px;
	letter-spacing: -0.05px;
	margin: 10px 0 20px;
}

p {
	font: 15px/22px "Inter UI", system-ui, sans-serif;
	color: #37352f;
	line-height: 1.5;
	letter-spacing: -0.002em;
	font-weight: 400;
	margin: 20px 0;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-overflow-scrolling: touch;
	scroll-behavior: smooth;
	font-kerning: normal;
	-moz-font-feature-settings: "calt" 1, "kern" 1, "liga" 1;
	-ms-font-feature-settings: "calt" 1, "kern" 1, "liga" 1;
	-o-font-feature-settings: "calt" 1, "kern" 1, "liga" 1;
	-webkit-font-feature-settings: "calt" 1, "kern" 1, "liga" 1;
	font-feature-settings: "calt" 1, "kern" 1, "liga" 1;
}

div {
	vertical-align: top;
}

svg {
	overflow: visible;
}
line[stroke="#666"] {
	stroke-width: 2;
	stroke: #bfc5d166;
}

a {
	text-underline-position: under;
	transition: all 200ms ease;
}

.react-tooltip {
	z-index: 999999;
	pointer-events: none;
	position: absolute;
	background: #10293c;
	color: #fff;
	padding: 4px 8px;
	font-weight: 600;
	font-size: 11px;
	border-radius: 4px;
	white-space: nowrap;
	opacity: 0;
	transition: all 75ms ease-in;
}

.react-toast-notifications__container {
	overflow: hidden !important;
}

.typed-cursor {
	margin-left: 2px !important;
	animation-duration: 1s !important;
	font-weight: 400 !important;
}
